import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Inject } from 'inversify-props'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarEmissaoDeNota, criarVendaDoPdv } from '@/shareds/venda-shareds'
import { ItemDaVenda } from "@/models"
import { VendaServiceAdapter } from '../venda'
import { PreVendaServiceAdapter } from './adapter'
import { PreVenda } from '@/models/pre-venda/PreVenda'
import AplicarTabelaDePrecoUseCase from '../tabela-de-precos/AplicarTabelaDePrecoUseCase'
import { ClienteServiceAdapter } from '../cliente'

export class ImportarPreVendaUseCase {
	@Inject('PreVendaServiceAdapter')
	private preVendaService!: PreVendaServiceAdapter

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter
	

	async importarPreVenda(identificador: string, telaDeEmissaoDeNota: boolean) {
		try{
			let preVenda: PreVenda
			const loja = telaDeEmissaoDeNota 
				? VendaModule.lojaDaEntrada
				: VendaModule.lojaDaVenda
			
			if(!loja) throw new Error('Loja não selecionada')
			if((VendaModule.turnoDeVenda == null || !VendaModule.turnoDeVenda.pontoDeVenda) && !telaDeEmissaoDeNota) throw new Error('Pdv de venda não selecionado')

			if(telaDeEmissaoDeNota) {	
				preVenda = await this.preVendaService.findPreVendaByFiltro({
					identificador: identificador,
					lojaId: loja.id,
					cnpjDestinatario: VendaModule.emissaoEntradaAtual?.cpfDoCliente,
				})
			} else {
				preVenda = await this.preVendaService.findPreVendaByIdentificador(identificador)
			}

			const novoIdentificador = await this.vendaService.gerarIdentificadorDeVendaSemTurno(loja.id)
			
			const novaVenda = !telaDeEmissaoDeNota && VendaModule.turnoDeVenda
				? criarVendaDoPdv(VendaModule.turnoDeVenda.pontoDeVenda) 
				: criarEmissaoDeNota() 

			if(preVenda.cpfOuCnpjDoCliente && preVenda.cpfOuCnpjDoCliente.length > 0) {
				novaVenda.cliente = await this.clienteService.get(preVenda.cpfOuCnpjDoCliente) || null
				novaVenda.cpfDoCliente = VendaModule.vendaAtual?.cpfDoCliente || preVenda.cpfOuCnpjDoCliente || ''
			}
 
			novaVenda.loja = loja.id
			novaVenda.identificador = novoIdentificador
			novaVenda.cuponsDeDesconto = []
			novaVenda.tipoDeTransacao = VendaModule.emissaoEntradaAtual?.tipoDeTransacao || 'Venda'
			novaVenda.isBrinde = VendaModule.vendaAtual?.isBrinde || false
			novaVenda.isConsignado = VendaModule.vendaAtual?.isConsignado || false
			novaVenda.isDemonstracao = VendaModule.vendaAtual?.isDemonstracao || false

			const itensFormatados = preVenda.itens.map<ItemDaVenda>(item => ({
				id: '',
				desconto: {
					isPercentual: false,
					valor: 0,
				},
				quantidade: item.quantidade,
				preco: item.preco,
				precoDeCusto: 0,
				produto: item.produto,
				idConjunto: null,
				descontoVarejo: 0,
				percentualCashback: 0,
				diasParaEfetivarCashback: 0,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: item.chaveNotaOrigem,
				diasParaExpirarCashback: 0,
				possuiDescontoPorTabela: false,
				valorDescontoRateadoDoItem: 0,
				itemDeDevolucao: false,
				isTotalmenteTrocado: false,
				motivoCancelamento: null,
				isCompra: false,
				idItemOrigem: '',
				isDaVendaReaberta: false,
				vendedor: null,
				autenticadoParaLiberarProdutoSemEstoque: false,
			}))

			if(!telaDeEmissaoDeNota) {
				const itensComOPrecoAplicado = await AplicarTabelaDePrecoUseCase({
					loja: loja,
					itens: itensFormatados,
					tipoDeCliente: null,
					validarLimiteDeProduto: false,
					vendaAtual: novaVenda,
				})
				novaVenda.itens = itensComOPrecoAplicado
			} else {
				novaVenda.itens = itensFormatados
			}

			novaVenda.idPreVenda = preVenda.id
			VendaModule.importarVenda({vendaImportar: novaVenda, telaDeEmissaoDeNota})
			VendaModule.setVendaOriginadaDaPreVenda(true)
		
		} catch(e){
			AlertModule.setError(e)
			throw e
		}
	}
}