import { container } from 'inversify-props'
import { VendaServiceAdapter } from './adapter'
import { ItemDaVenda, Venda } from '@/models'
import {
	imprimirNotaFiscal as imprimirNotaFiscalPadrao,
	unificarItensDaVendaPorProduto,
} from '@/shareds/venda-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindEstoqueUseCase } from '../deposito'
import { PreVendaUseCase } from './PreVendaUseCase'
import { PreVenda } from '@/models/pre-venda/PreVenda'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

type UseCaseParams = {
	venda: Venda
	service?: VendaServiceAdapter
	imprimirNotaFiscal?: (idVenda: string, imprimirNfe?: boolean, isContingencia?: boolean) => Promise<any>
	vendaModule?: typeof VendaModule
	findEstoqueUseCase?: FindEstoqueUseCase
	preVendaUseCase?: PreVendaUseCase
	itensDevolucao?: ItemDaVenda[]
}

export async function EmitirNotaUseCase({
	service = container.get<VendaServiceAdapter>('VendaServiceAdapter'),
	imprimirNotaFiscal = imprimirNotaFiscalPadrao,
	vendaModule = VendaModule,
	venda,
	findEstoqueUseCase = new FindEstoqueUseCase(),
	preVendaUseCase = new PreVendaUseCase(),
}: UseCaseParams): Promise<Venda> {
	if (!venda) throw new Error('Venda não informada')
	if (!venda.itens || venda.itens.length === 0) throw new Error('Venda não possui itens')
	if (!vendaModule.lojaDaEntrada) throw new Error('Loja não selecionada')

	let identificador = ''
	try {
		identificador = await service.gerarIdentificadorDeVendaSemTurno(vendaModule.lojaDaEntrada.id)
	} catch (error) {
		throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
	}

	if (venda.tipoDeTransacao === 'Venda') {
		const itensUnificados = unificarItensDaVendaPorProduto(venda.itens)

		if(!vendaModule.lojaDaVenda?.configuracaoDaLoja.podeGerarEstoqueNaVenda) {
			const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque

			let projecoesPorItemDaVenda
			if(!podeLiberarEstoque) {
				projecoesPorItemDaVenda = await Promise.all(
					itensUnificados.map(async (itemDaVenda: any) => {
						let criticas = ''
						
						await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
							itemDaVenda.id, itemDaVenda.quantidade.toString(), vendaModule.lojaDaEntrada?.id || '', itemDaVenda.autenticandoParaLiberarVendaSemEstoque,
						).catch((error) => {
							criticas = error instanceof Object ? error.response.data[0] : error
						})
						return criticas
					}),
				)
				projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
			}
			const criticasNormalizadas = projecoesPorItemDaVenda
				? projecoesPorItemDaVenda.join('<br>')
				: undefined
			if (criticasNormalizadas) {
				throw criticasNormalizadas
			}
		} else {
			let projecoesPorItemDaVenda = await Promise.all(
				itensUnificados.map(async (itemDaVenda: any) => {
					let criticas = ''
					await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
						itemDaVenda.id, itemDaVenda.quantidade.toString(), vendaModule.lojaDaEntrada?.id || '', itemDaVenda.autenticandoParaLiberarVendaSemEstoque,
					).catch((error) => {
						criticas = error instanceof Object ? error.response.data[0] : error
					})
					return criticas
				}),
			)

			projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
			const criticasNormalizadas = projecoesPorItemDaVenda
				? projecoesPorItemDaVenda.join('<br>')
				: undefined
			if (criticasNormalizadas) {
				throw criticasNormalizadas
			}
		}
	}

	const vendaAEnviar: Venda = {
		...venda,
		troco: 0,
		tipoDeCliente: venda.tipoDeCliente || null,
		dataHora: venda.dataHora || new Date(),
		identificador: identificador,
		itens: venda.itens.filter(item => item.quantidade > 0 || item.quantidade < 0),
		isConsignado: venda.isVendaReaberta ? false : venda.isConsignado,
		isDemonstracao: venda.isVendaReaberta ? false : venda.isDemonstracao,
		loja: vendaModule.lojaDaEntrada.id,
		autenticandoParaLiberarVendaSemEstoque: UserLoginStore.autenticandoParaLiberarVendaSemEstoque,
	}

	vendaModule.setEmissaoEntradaAtual(vendaAEnviar)
	const vendaAtualizada = await service.create(vendaAEnviar, true)

	try {
		await imprimirNotaFiscal(vendaAtualizada.id, true)
	} catch(error) {
		AlertModule.setError(error)
	}

	if(VendaModule.emissaoEntradaAtual?.idPreVenda) {
		try {
			const preVenda: PreVenda = {
				id: VendaModule.emissaoEntradaAtual?.idPreVenda,
				identificador: '',
				situacao:  'Concluído',
				dataHoraCriacao: null,
				itens: [],
				loja: null,
				cpfOuCnpjDoCliente: VendaModule.emissaoEntradaAtual.cpfDoCliente,
			}
			await preVendaUseCase.updateSituacao(preVenda)
			VendaModule.recarregaVenda
		} catch(error) {
			throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
		}
	}

	vendaModule.iniciarNovaNota({identificador, lojaId: vendaModule.lojaDaEntrada.id})
	UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

	return vendaAtualizada
}